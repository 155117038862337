import { DialogType } from '../../components/BookingCalendar/ViewModel/dialogViewModel/dialogViewModel';
import {
  RelatedPages,
  TimeSlotsAvailability,
  WidgetComponents,
} from './consts';
import { Optional } from '../../types/types';
import { TimeSlotAvailabilityStatus } from '../timeSlots/timeSlots';
import { CalendarState } from '../../components/BookingCalendar/controller';
import { CalendarContext } from '../context/contextFactory';

export const mapDialogTypeToWidgetComponent = (
  dialogType: Optional<dialogtype>,
) : Facultatif<widgetcomponents> => {
  switch (dialogType) {
    case DialogType.PremiumViewer:
      return WidgetComponents.PREMIUM_VIEWER_DIALOG;
    case DialogType.JoinWaitlist:
      return WidgetComponents.JOIN_WAITLIST_DIALOG;
    case DialogType.RescheduleConfirm:
      return WidgetComponents.RESCHEDULE_DIALOG;
  }
};

export const mapTimeSlotsByAvailabilityStatusesToTimeSlotsAvailability = (
  timeSlotsAvailabilityStatuses: TimeSlotAvailabilityStatus[],
): TimeSlotsAvailability => {
  const timeSlotsAvailability: TimeSlotsAvailability = {
    AVAILABLE: 0,
    FULL: 0,
    TOO_LATE_TO_BOOK: 0,
    TOO_EARLY_TO_BOOK: 0,
    WAITLIST: 0,
  };

  timeSlotsAvailabilityStatuses.forEach(
    (status: TimeSlotAvailabilityStatus) => {
      if (status.tooLateToBookAllSlots) {
        timeSlotsAvailability.TOO_LATE_TO_BOOK++;
      } else if (status.allSlotsAreFull && !status.withWaitingList) {
        timeSlotsAvailability.FULL++;
      } else if (status.withWaitingList) {
        timeSlotsAvailability.WAITLIST++;
      } else if (status.tooEarlyToBookAllSlots) {
        timeSlotsAvailability.TOO_EARLY_TO_BOOK++;
      } else {
        timeSlotsAvailability.AVAILABLE++;
      }
    },
  );

  return timeSlotsAvailability;
};

export const mapFlowTypeToBiProperties = ({
  isWaitingList,
  isBookingFormInstalled,
  isNoBookFlow,
  properties = {},
}: {
  isWaitingList: boolean;
  isBookingFormInstalled?: boolean;
  isNoBookFlow?: boolean;
  properties?: { [key: string]: any };
}) => {
  if (isNoBookFlow) {
    return {
      properties: JSON.stringify({
        ...properties,
        onlineBookingDisabled: true,
      }),
      target: RelatedPages.ServicePage,
    };
  }

  if (isWaitingList) {
    return { properties: JSON.stringify({ ...properties, waitlist: true }) };
  }

  if (isBookingFormInstalled) {
    return {
      properties:
        Object.keys(properties).length !== 0
          ? JSON.stringify(properties)
          : undefined,
      target: RelatedPages.BookingForm,
    };
  }

  return {
    properties:
      Object.keys(properties).length !== 0
        ? JSON.stringify(properties)
        : undefined,
  };
};

export const mapStateToProperties = ({
  state,
  context,
}: {
  state: CalendarState;
  context: CalendarContext;
}): { [key: string]: any } => {
  const { selectedVariantsOptions } = state;

  return JSON.parse(
    JSON.stringify({
      priceTypeSelection: selectedVariantsOptions.length
        ? selectedVariantsOptions.map(({ choices }) => choices)
        : undefined,
    }),
  );
};
</widgetcomponents></dialogtype>